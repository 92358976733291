import React from "react"
import Layout from "../components/layout"
import Page from "../components/page"
import "../styles/spam.css"

const SpamPage = () => (
  <Layout>
    <Page title="Anti-spam policy">
      <section className="part unnumberedpart">
        <ol className="sections">
          <li className="section numberedsection">
            <ol className="units">
              <li className="sectionTitle">
                <h3>Introduction</h3>
              </li>
              <li className="unit numberedunit">
                In the context of electronic messaging, &quot;spam&quot; means
                unsolicited, bulk or indiscriminate messages, typically sent for
                a commercial purpose.
              </li>
              <li className="unit numberedunit">
                We have a zero-tolerance spam policy.
              </li>
            </ol>
          </li>
          <li className="section numberedsection">
            <ol className="units">
              <li className="sectionTitle">
                <h3>Spam filtering</h3>
              </li>
              <li className="unit numberedunit">
                Our messaging systems automatically scan all incoming email and
                other messages and filter out messages that appear to be spam.
              </li>
              <li className="unit numberedunit">
                We may also report incoming email as spam. This can result in IP
                addresses and domain names being blacklisted.
              </li>
            </ol>
          </li>
          <li className="section numberedsection">
            <ol className="units">
              <li className="sectionTitle">
                <h3>Spam filtering issues</h3>
              </li>
              <li className="unit numberedunit">
                No message filtering system is 100% accurate, and from time to
                time legitimate messages will be filtered out by our systems.
              </li>
              <li className="unit numberedunit">
                If you believe that a legitimate message you have sent has been
                filtered out by our systems, please advise the message recipient
                by another means.
              </li>
              <li className="unit numberedunit">
                You can reduce the risk of a message being caught by the spam
                filters by:
                <ol className="listitem  ">
                  <li>
                    sending the message in plain text (instead of, or in
                    addition to, HTML);
                  </li>
                  <li>removing any message attachments;</li>
                  <li>
                    avoiding the terminology and text styling typically used by
                    spammers; and/or
                  </li>
                  <li>
                    ensuring that your messages are scanned for malware before
                    dispatch.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li className="section numberedsection">
            <ol className="units">
              <li className="sectionTitle">
                <h3>Receipt of unwanted messages from us</h3>
              </li>
              <li className="unit numberedunit">
                In the unlikely event that you receive any message from us or
                sent using our systems that may be considered to be spam, please
                contact us using the details below and the matter will be
                investigated.
              </li>
            </ol>
          </li>
          <li className="section numberedsection">
            <ol className="units">
              <li className="sectionTitle">
                <h3>Variation</h3>
              </li>
              <li className="unit numberedunit">
                We may amend this policy at any time by publishing a new version
                on our website.{" "}
              </li>
            </ol>
          </li>
          <li className="section numberedsection">
            <ol className="units">
              <li className="sectionTitle">
                <h3>Our details</h3>
              </li>
              <li className="unit numberedunit">
                This website is owned and operated by petercampanelli.com LLC.
              </li>
              <li className="unit numberedunit">
                You can contact us by email:{" "}
                <a href="mailto:support@peter.digital">support@peter.digital</a>
              </li>
            </ol>
          </li>
        </ol>
      </section>
    </Page>
  </Layout>
)

export default SpamPage
